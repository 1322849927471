import CSSPlugin from 'gsap/CSSPlugin';
import { gsap, Power4 }  from "gsap"; // Also works with TweenLite and TimelineLite
const myPlugins = [CSSPlugin]

import $ from 'jquery'

// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import 'slick-carousel'

const mosaicSlider = {
	delay:  () => {return 8000},
	
	init: () => {
		if(document.querySelector('.mosaic_slider')){
			let index = 0,
				numSlides = document.querySelector(".mosaic_slider").querySelectorAll(".mosaic_slider_item").length
			
			index = mosaicSlider.changeSlide(index)

			setInterval(() => {
				index = mosaicSlider.changeSlide(index)
				if(index >= numSlides){
					index = 0
				}
			}, mosaicSlider.delay())

		}
	}, 
	changeSlide: (index) => {
		let activeSlides = document.querySelectorAll(`.mosaic_slider_item.is-active`)
		let slidesInd = document.querySelectorAll(`.js-mosaic-slider-item-${index}`)

		activeSlides.forEach((i,cont) => {setTimeout(() => {
											i.classList.remove('is-active')
											}, mosaicSlider.delay() / 4 * cont)})
		slidesInd.forEach((i,cont) => {setTimeout(() => {
										i.classList.add('is-active')
										}, mosaicSlider.delay() / 4 * cont)})
		return index = index + 1
	}
}

const sliderBlock = {
	delay:  () => {return 10000},
	init: (id) => {
		let slider = document.querySelector(`#${id}`)
		let index = 0
		let slideCount = slider.querySelectorAll('.slider-block_contents_inner').length
		// let json = JSON.parse(slider.getAttribute('data-json'))
		// let locale = slider.getAttribute('data-locale')
		let nav = document.querySelectorAll('.slider-block_nav')
		let timer

		timer = setInterval(() => {
			index = index + 1
			if (index >= slideCount){ index = 0}
			sliderBlock.changeSlide(index, slider)
			}, sliderBlock.delay()
		)
		nav.forEach(nav => {
			nav.addEventListener('click', (e) => {
				clearInterval(timer)
				if(e.currentTarget.getAttribute('data-dir') == 'prev'){
					index = index - 1
				} else {
					index = index + 1
				}
				if (index < 0){ index = slideCount - 1} 
				if (index >= slideCount){ index = 0}
				sliderBlock.changeSlide(index, slider)
				timer = setInterval(() => {
					index = index + 1
					if (index >= slideCount){ index = 0}
					sliderBlock.changeSlide(index, slider)
					}, sliderBlock.delay()
				)
			})
		})

	}, 
	changeSlide: (ind, slider) => {

		// let slideImages = slider.querySelectorAll(`.js-slide-image`)
		let newSlideContent = slider.querySelector(`.js-slide-content-${ind}`)
		let slideContents = slider.querySelectorAll('.slider-block_contents_inner')
		
		let newSlideImage = slider.querySelector(`.js-slide-image-${ind}`)
		let slideImages = slider.querySelectorAll('.background-image')

		slideContents.forEach(i => i.classList.remove('is-active'))
		newSlideContent.classList.add('is-active')

		slideImages.forEach(i => i.classList.remove('is-active'))
		newSlideImage.classList.add('is-active')
		// per cambiare colore ai div fuori dalla slide dobbiamo usare il js 
		// attenzione alla posizione della foto
		let navToBeChanged
		let bdSide
		if (slider.getAttribute('data-image-position') == 'right'){
			navToBeChanged = slider.querySelector(`.slider-block_nav[data-dir='prev']`)
			bdSide = 'l'
		} else {
			navToBeChanged = slider.querySelector(`.slider-block_nav[data-dir='next']`)
			bdSide = 'r'
		}

		let colorScheme = newSlideContent.getAttribute('data-color-scheme')


		navToBeChanged.classList.remove('cscheme-white')
		navToBeChanged.classList.remove('cscheme-gold')
		navToBeChanged.classList.remove('cscheme-brown')
		navToBeChanged.classList.remove('cscheme-brown-med')
		navToBeChanged.classList.remove('cscheme-green')
		navToBeChanged.classList.add(`cscheme-${colorScheme}`)

		return ind
	}
}

const sliderNumbers = {

	init: () => {
		let slidesNum = $('.slider-number_item').count
		$('.slider-numbers').slick({
			infinite: true,
			slidesToShow: 4, 
			slidesToScroll: 1,
			touchThreshold:100,
			autoplay: true,
  			autoplaySpeed: 3000, 
  			arrows: true, 
  			initialSlide: -1, 
  			dots: false, 
  			draggable: true,
  			swipeToSlide: true, 
  			prevArrow: false,
    		nextArrow: false,
  			// prevArrow: '<div class="chevron chevron-left slider-numbers_prev bg-grey"></div>',
  			// nextArrow: '<div class="chevron slider-numbers_next bg-grey"></div>', 
  			responsive: [{
  				breakpoint: 900,
	  				settings:{
	  					slidesToShow: 3
	  				}
  				},
  				{
  				breakpoint: 768,
	  				settings:{
	  					slidesToShow: 2
	  				}
  				}, {	
  					breakpoint: 576,
  					settings:{
  						slidesToShow: 1
  					}
  				}
  			]
		})

	}
}

const sliderImages = {
	init: () => {
		$('.slider-images').slick({
				infinite: true,
				slidesToShow: 1, 
				slidesToScroll: 1,
				// autoplay: true,
	  			autoplaySpeed: 1000, 
	  			arrows: true, 
	  			initialSlide: 0, 
	  			dots: true, 
	  			 rows: 0,
	  			prevArrow: '<div class="slider-images_prev"><div class="chevron chevron-left bg-white"></div> </div>',
	  			nextArrow: '<div class="slider-images_next"><div class="chevron bg-white"></div></div>'
			})
		if (document.querySelector('.slider-made-in-italy')){
			$('.slider-images').on('afterChange', function(event, slick, currentSlide){
				document.querySelectorAll('#made-in-italy .background-image').forEach(i => i.classList.remove('is-active'))
				document.querySelector(`[data-made-in-italy="${currentSlide}"]`).classList.add('is-active')
			})
		}
	}
}

const latestNews = {
	init: () => {
		$('.latest-news_slider').slick({
			infinite: true,
			slidesToShow: 2, 
			slidesToScroll: 1,
  			arrows: true, 
  			initialSlide: 0, 
  			dots: false, 
  			 rows: 0,
  			prevArrow: '<div class="chevron chevron-left latest-news_slider_prev bg-gold"></div>',
  			nextArrow: '<div class="chevron latest-news_slider_next bg-gold"></div>',  
  			responsive: [{
  				breakpoint: 900,
	  				settings:{
	  					slidesToShow: 1
	  				}
  				}
  			]
		})
	}
}

const sliderBig = {
	init: (id) => {
		let slider = document.querySelector(`#${id}`)
		let index = 0
		let json = JSON.parse(slider.getAttribute('data-json'))
		let locale = slider.getAttribute('data-locale')
		let nav = slider.querySelectorAll('.slider-big_nav, .slider-big_menu_nav')
		let menu = slider.querySelectorAll('.slider-big_menu_item')
 
		nav.forEach(nav => {
			nav.addEventListener('click', (e) => {
				if(e.currentTarget.getAttribute('data-dir') == 'prev'){
					index = index - 1
				} else {
					index = index + 1
				}
				if (index < 0){ index = json.length - 1} 
				if (index >= json.length){ index = 0}
				sliderBig.changeSlide(index, id, json, locale)
			})
		})

		menu.forEach(item => {
			item.addEventListener('click', (e) => {
				index = e.currentTarget.getAttribute('data-nav')
				sliderBig.changeSlide(index, id, json, locale)
			})
		})
	},
	changeSlide: (ind, id, json, locale) => {
		let slideTitle = document.querySelector(`#${id} .js-slide-title`)
		let slideImages = document.querySelectorAll(`#${id} .js-slide-image`)
		let slideBody = document.querySelector(`#${id} .js-slide-body`)
		let numSlides = json.length
		let title = json[ind][`title_${locale}`]
		let body = json[ind][`body_${locale}`]

		// NOTA: L'INDICE LO SI PUò CAMBIARE SOLO ALLA FINE DELL'ANIMAZIONE ALTRIMENTI 
		// NON SI AGGIORNA IL TITOLO,
		// non incrementiamo l'indice alla fine perchè il valore di ind viene incrementato prima
		// della conclusione dell'animazione.
		gsap.to(slideTitle, {duration: 0.3, opacity: 0, onComplete: 
				() => {
					slideTitle.innerHTML = title
					gsap.fromTo(slideTitle, {opacity: 0, y: 20},{duration: 0.5, opacity: 1, y: 0, ease: Power4.out})
				}})
		gsap.to(slideBody, {duration: 0.3, opacity: 0 , onComplete: 
				() => {				
					slideBody.innerHTML = body
					gsap.fromTo(slideBody, {opacity: 0, y: 20},{duration: 0.5, opacity: 1, y: 0})
				}})
		slideImages.forEach(i => {
			i.classList.remove('is-active')
			if(i.getAttribute('data-index') == ind){i.classList.add('is-active')}
		})

		document.querySelectorAll(`#${id} .slider-big_menu_item`).forEach(i => i.classList.remove('is-active'))
		document.querySelector(`#${id} .slider-big_menu_item[data-nav='${ind}']`).classList.add('is-active')
		
		return ind
	}
}

const sliderIngredients = {
	init: () => {
		$('.mosaic-ingredients-slider').slick({
			infinite: true,
			slidesToShow: 1, 
			slidesToScroll: 1,
			autoplay: true,
  			autoplaySpeed: 400000, 
  			arrows: true, 
  			initialSlide: 0, 
  			dots: false, 
  			draggable: true,
  			prevArrow: false,
    		nextArrow: false,
  			prevArrow: '<div class="chevron chevron-left mosaic-ingredients-slider_prev bg-white"></div>',
  			nextArrow: '<div class="chevron mosaic-ingredients-slider_next bg-white"></div>'
		})}
}

document.addEventListener('DOMContentLoaded', () => {
	mosaicSlider.init()
	if(document.querySelector('.slider-block')){
		document.querySelectorAll('.slider-block').forEach(i => {
			let sliderId  = i.getAttribute('id')
			sliderBlock.init(sliderId)
		})
	}

	if(document.querySelector('.slider-big')){
		document.querySelectorAll('.slider-big').forEach(i => {
			let sliderId  = i.getAttribute('id')
			sliderBig.init(sliderId)
		})
	}

	if(document.querySelector('.slider-numbers')){
		sliderNumbers.init()
	}

	if(document.querySelector('.slider-images')){
		sliderImages.init()
	}
	if(document.querySelector('.latest-news_slider')){
		latestNews.init()
	}

	if(document.querySelector('.mosaic-ingredients-slider')){
		sliderIngredients.init()
	}
})
